<template>
    <div v-loading="isLoading" class="manuscript">
        <div class="header">
            <img src="~@/assets/img/login/logo.png" />
            {{ $store.state.user.userName }}&nbsp;{{ $store.state.user.sex == 1 ? '先生' : $store.state.user.sex == 2 ? '女士' : '' }}
            <div class="header-right">
                <!-- <i class="el-icon-menu" @click="projectMenu" /> -->
                <span class="exit" @click="loginOut">
                    <i class="el-icon-right" />
                </span>
            </div>
        </div>
        <div class="project">
            <p>当前办理项目： {{ progressData.productName }}</p>
            <p>签约时间： {{ progressData.signTime }}</p>
        </div>
        <div class="list">
            <div>
                <p v-if="writingArticlesList.length > 0" class="list-label">推荐信/赴港计划书</p>
                <template v-if="writingArticlesList.length > 0">
                    <div v-for="(row) in writingArticlesList" :key="row.id" class="list-data">
                        <div @click="openDocument(row)">
                            <p>{{ row.name }}
                                <span v-if="row.supplementListNameExtraJson && row.supplementListNameExtraJson.organization">({{ row.supplementListNameExtraJson.organization }})</span>
                                <span v-if="row.supplementListNameExtraJson && row.supplementListNameExtraJson.applicant">【{{ row.supplementListNameExtraJson.applicant }}】</span>
                            </p>
                            <p v-if="row.status == 6" style="color: #F59A23;">{{ row.status | documentsStatus }}</p>
                            <p v-else style="color:#7F7F7F;">{{ row.status | documentsStatus }}</p>
                        </div>
                        <div class="icon" @click="more(row)">
                            <i class="el-icon-more" />
                        </div>
                    </div>
                </template>
                <p v-if="otherFileList.length > 0" class="list-label">其他文件</p>
                <template v-if="otherFileList.length > 0">
                    <div v-for="(row) in otherFileList" :key="row.id" class="list-data">
                        <div @click="openDocument(row)">
                            <p>{{ row.name }}
                                <span v-if="row.supplementListNameExtraJson && row.supplementListNameExtraJson.organization">({{ row.supplementListNameExtraJson.organization }})</span>
                                <span v-if="row.supplementListNameExtraJson && row.supplementListNameExtraJson.applicant">【{{ row.supplementListNameExtraJson.applicant }}】</span>
                            </p>
                            <p v-if="row.status == 6" style="color: #F59A23;">{{ row.status | documentsStatus }}</p>
                            <p v-else style="color:#7F7F7F;">{{ row.status | documentsStatus }}</p>
                        </div>
                        <div class="icon" @click="more(row)">
                            <i class="el-icon-more" />
                        </div>
                    </div>
                </template>
            </div>
            <div v-if="writingArticlesList.length === 0 && otherFileList.length === 0" style="padding: 50px 0;text-align: center;font-size: 14px;">暂无数据</div>
        </div>
        <!-- 操作按钮 -->
        <div class="popup">
            <div v-show="showCard" class="popup-bg"/>
            <div class="bottom-card" :class="showCard ? 'bottom-card-in' : 'bottom-card-out'">
                <div class="popup-title">
                    <p>{{ manuscriptRow.name }}
                        <span v-if="manuscriptRow.supplementListNameExtraJson && manuscriptRow.supplementListNameExtraJson.organization">({{ manuscriptRow.supplementListNameExtraJson.organization }})</span>
                        <span v-if="manuscriptRow.supplementListNameExtraJson && manuscriptRow.supplementListNameExtraJson.applicant">【{{ manuscriptRow.supplementListNameExtraJson.applicant }}】</span>
                    </p>
                    <p style="color:#7F7F7F;">{{ manuscriptRow.status | documentsStatus }}</p>
                    <div class="popup-close" @click="showCard = false">
                        <i class="el-icon-error" />
                    </div>
                </div>
                <div class="popup-btns">
                    <template v-if="manuscriptRow.status === 2">
                        <p>
                            <el-button v-if="manuscriptRow.id" type="primary" style="width: 100%;" @click="commitDocument(manuscriptRow)">
                                <i class="el-icon-s-promotion" />
                                <span>提交文件</span>
                            </el-button>
                        </p>
                        <p>
                            <el-button type="primary" style="width: 100%;" @click="openDocument(manuscriptRow)">
                                <i class="el-icon-edit" />
                                <span>编辑文件</span>
                            </el-button>
                        </p>
                    </template>
                    <p v-else>
                        <el-button type="primary" style="width: 100%;" @click="openDocument(manuscriptRow)">
                            <i class="el-icon-tickets" />
                            <span>查看文件</span>
                        </el-button>
                    </p>
                    <!-- <p>
                        <el-button type="primary" style="width: 100%;" @click="downloadDocument(manuscriptRow)">
                            <i class="el-icon-download" />
                            <span>下载文件</span>
                        </el-button>
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getGangedProgress } from "@/api/workfow.js"
import cryptoAES from '../../../utils/cryptoAES'
import { queryWritingDocumentList, commitDocumentChanges, getTopVersionDocumentUrl } from '../../../api/manuscript'

export default {
    filters: {
        documentsStatus(v) {
            switch (v) {
                case 1:
                    return "待提交";
                case 2:
                    return "待确认";
                case 3:
                    return "待核对";
                case 4:
                    return "待审核";
                case 5:
                    return "驳回";
                case 6:
                    return "已定稿";
                default:
                    break;
            }
        }
    },
    data() {
        return {
            showCard: false,
            progressData: {},
            isLoading: false,
            manuscriptRow: {},
            isDialog: false,
            writingArticlesList: [],
            otherFileList: [],
        }
    },
    created() {
        if(!localStorage.getItem('token')) {
            this.$router.push({
                path: "/Login",
                query: this.$route.query
            });
            return false
        }
        this.$store.commit("user")
        this.$store.commit("setType")
        this.progressList()
        this.getDocumentList()
    },
    methods: {
        more(row) {
            this.manuscriptRow = row
            this.showCard = true
        },
        // 获取用户签约信息
        async progressList() {
            const data = await getGangedProgress({
                orderId: this.$route.query.orderId,
                productType: 1 // 固定1 ，这个是不对的，因为业务上没关联，后续迭代需注意
            })
            this.progressData = data.data
        },
        // 列表
        async getDocumentList() {
            try {
                this.isLoading = true
                const { data } = await queryWritingDocumentList({
                    orderId: this.$route.query.orderId
                })
                this.writingArticlesList = data.writingArticlesList
                this.otherFileList = data.otherFileList
            } finally {
                this.isLoading = false
            }
        },
        // 编辑完成提交
        commitDocument(row) {
            this.showCard = false
            this.$confirmTip
                .showTip('确定要提交，并确保编辑文档页面已经关闭?')
                .$on('confirm', async () => {
                    try {
                        this.isLoading = true
                        const { code } = await commitDocumentChanges({
                            id: row.id
                        })
                        if (code === 200) {
                            this.$toastTip.success('提交成功!')
                            this.getDocumentList()
                        } else {
                            setTimeout(() => {
                                this.getDocumentList()
                            }, 3000)
                        }
                    } finally {
                        this.isLoading = false
                    }
                })
        },
        openDocument(row) {
            this.showCard = false
            if (row.documentKey) {
                const scope = row
                const query = {
                    documentKey: scope.documentKey,
                    uid: '2-' + row.customerId,
                    compareFileUrl: this.$utils.toOss(scope.compareFileUrl),
                    token: localStorage.getItem('token'),
                    ...{ account: this.$store.state.user.userName },
                    type: 'mobile'
                }
                const documentInfo = encodeURIComponent(cryptoAES.encrypt(encodeURIComponent(JSON.stringify(query))))
                location.href = process.env.VUE_APP_DOC_URL + '/Writing?documentInfo='+ documentInfo
            }
            else {
                location.href = this.$utils.toOss(JSON.parse(row.documentUrl)[0].url)
            }
        },
        // 退出
        loginOut() {
            localStorage.clear();
            this.$router.push({
                path: "/Login",
                query: this.$route.query
            });
        },
        // 项目
        projectMenu() {
            this.$router.push({
                path: "/MobileProjectList",
                query: this.$route.query
            });
        },
        // 下载文件
        async downloadDocument(row) {
            try {
                this.isLoading = true
                const { code, data } = await getTopVersionDocumentUrl({documentKey: row.documentKey})
                if (code === 200) {
                    this.showCard = false
                    const u = navigator.userAgent;
                    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
                    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                    if(isAndroid) {
                        window.open(data)
                    }
                    if (isiOS) {
                        window.location.href = data
                    }

                }
            } finally {
                this.isLoading = false
            }
        }
    }
}
</script>

<style lang="less" scoped>
.manuscript {
    height: 100%;
    background: #fff;
    padding: 10px 20px;
    .header {
        position: relative;
        margin-top: 10px;
        font-size: 18px;
        color: #7f7f7f;
        img {
            width: 40px;
            border: 1px solid #aaa;
            border-radius: 30px;
            padding: 3px;
            margin-right: 10px;
        }
        .header-right {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -15px;
            > i {
                font-size: 27px;
                color: #409eff;
            }
            .exit {
                margin-left: 10px;
                display: inline-block;
                border: 2px solid #409eff;
                position: relative;
                height: 22px;
                width: 18px;
                i {
                    position: absolute;
                    top: 50%;
                    margin-top: -8px;
                    right: -8px;
                    font-size: 15px !important;
                    background: #fff;
                    color: #409eff;
                }
            }
        }
    }
    .project {
        background: #409eff;
        padding: 10px 20px 15px;
        color: #fff;
        border-radius: 5px;
        margin-top: 20px;
        p {
            padding-top: 10px;
            font-size: 13px;
        }
    }
    .list {
        .list-label {
            color: #409eff;
            border-left: 4px solid #409eff;
            margin-top: 30px;
            padding-left: 10px;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .list-data {
            position: relative;
            padding: 10px 35px 10px 0;
            border-bottom: 1px solid #f2f2f2;
            p:nth-child(1) {
                font-size: 14px;
                white-space: nowrap;/*内容超宽后禁止换行显示*/
                overflow: hidden;/*超出部分隐藏*/
                text-overflow:ellipsis;/*文字超出部分以省略号显示*/
            }
            p:nth-child(2) {
                margin-top: 5px;
            }
        }
        .icon {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -15px;
            i {
                color: #409eff;
                font-size: 28px;
            }
        }
    }
    @keyframes popup {
        from {
            height: 0;
        }
        to {
            height: 250px;
        }
    }
    @keyframes popout {
        from {
            height: 250px;
        }
        to {
            height: 0;
            opacity: 0;
        }
    }
    .popup {
        .popup-bg {
            position: fixed;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0.5;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }
    .bottom-card {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 250px;
        background: #fff;
        opacity: 0;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        &.bottom-card-in {
            animation: popup ease .5s;
            opacity: 1;
        }
        &.bottom-card-out {
            animation: popout ease-out .5s;
            height: 0;
        }
    }
    .popup-title {
        position: relative;
        padding: 20px 20px 10px;
        border-bottom: 1px solid #f2f2f2;
        p:nth-child(1) {
            font-size: 13px;
            margin-bottom: 5px;
        }
    }
    .popup-close {
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -15px;
        i {
            font-size: 25px;
            color: #409eff;
        }
    }
    .popup-btns {
        padding: 20px;
        p {
            padding-bottom: 10px;
            i {
                font-size: 20px;
            }
            span {
                font-size: 14px;
            }
        }
    }
    .dialog {
        position: fixed;
        text-align: center;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        .content {
            position: fixed;
            top: 45%;
            left: 50%;
            width: 320px;
            overflow: hidden;
            font-size: 16px;
            background-color: #fff;
            border-radius: 16px;
            transform: translate3d(-50%, -50%, 0);
            transition: 0.3s;
            transition-property: transform, opacity, -webkit-transform;
        }
        .dialog-footer {
            border-top: 1px solid #f2f2f2;
            display: flex;
            overflow: hidden;
            user-select: none;
            > div {
                flex: 1;
                height: 48px;
                line-height: 48px;
                font-size: 16px;
                cursor: pointer;
            }
            > div:nth-child(1) {
                border-right: 1px solid #f2f2f2;
            }
            > div:nth-child(2) {
                color: red;
            }
        }
        .dialog-header {
            padding-top: 26px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            font-size: 16px;
        }
        .dialog-message {
            flex: 1;
            max-height: 60vh;
            padding: 26px 24px;
            overflow-y: auto;
            font-size: 14px;
            line-height: 20px;
            white-space: pre-wrap;
            text-align: center;
            word-wrap: break-word;
            -webkit-overflow-scrolling: touch;
            padding-top: 8px;
            color: #646566;
        }
    }
}
</style>
